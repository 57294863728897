/* eslint-disable camelcase */
import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const StyledBackgroundImage = styled(Plaatjie)`
  width: 100%;
  height: calc(100% - 3rem);
  position: absolute !important;
  z-index: -1;
`

const Content = styled(ParseContent)`
  padding: 80px 0 80px 0;
  color: ${(props) => props.theme.color.text.dark};

  & h2,
  h3,
  h4,
  h5 {
    padding-bottom: 50px;
    font-size: ${(props) => props.theme.font.size['75']};
    line-height: 100px;
  }

  & p {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size['30']};
    line-height: 36px;
  }

  @media screen and (max-width: 991px) {
    & h2,
    h3,
    h4,
    h5 {
      font-size: ${(props) => props.theme.font.size[50]};
      line-height: ${(props) => props.theme.font.size[50]};
    }

    & p {
      font-family: ${(props) => props.theme.font.family.secondary};
      font-size: ${(props) => props.theme.font.size[20]};
      line-height: 20px;
    }
  }

  @media screen and (max-width: 576px) {
    padding: 40px 0 40px 0;

    & h2,
    h3,
    h4,
    h5 {
      padding-bottom: 20px;
      font-size: ${(props) => props.theme.font.size[40]};
      line-height: 46px;
    }

    & p {
      font-family: ${(props) => props.theme.font.family.secondary};
      font-size: ${(props) => props.theme.font.size[16]};
      line-height: 16px;
    }
  }

  @media screen and (max-width: 423px) {
    padding: 20px 0 20px 0;

    & h2,
    h3,
    h4,
    h5 {
      padding-bottom: 20px;
      font-size: ${(props) => props.theme.font.size[30]};
      line-height: 36px;
    }
  }
`

const Wrapper = styled.div`
  max-width: 2000px;
  position: relative;

  @media screen and (min-width: 2000px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 991px) {
    height: fit-content;
  }
`

interface HeroWithTextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_HeroWithText
}

const HeroWithText: React.FC<HeroWithTextProps> = ({ fields }) => (
  <Wrapper className="py-4 my-2">
    <StyledBackgroundImage image={fields?.image} alt="" />
    <div className="container py-lg-4">
      <Content content={fields.description} />
    </div>
  </Wrapper>
)

export default HeroWithText
